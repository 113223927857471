/* eslint-disable */
import React, { useEffect } from "react";
import "./FilterSheet.scss";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilterSheet = (props) => {
  const {
    state,
    currentThis,
    handleApplyFilter,
    handleClearAll,
    handleChange,
    closeFilterSheet,
    categorylist,
    handleDaysFilter,
    daysFilter,
    nextYearMonths,
    search_days,
    priceFilterOptions,
    priceFilter,
    handlePriceFilter,
  } = props;

  useEffect(() => {
    $("#rang-mobile").ionRangeSlider({
      min: state.startPrice,
      max: state.maxPrice,
      from: state.startPrice,
      to: state.endPrice,
      type: "double",
      grid_num: 10,
      onFinish: function (data) {
        currentThis.setState({ packageLoading: true });
        currentThis.setState(
          { startPrice: data.from, endPrice: data.to },
          function () {
            currentThis.loadFilter();
            currentThis.displayPackage();
          }
        );
        setTimeout(() => {
          currentThis.setState({ packageLoading: false });
        }, 2000);
      },
    });
  }, [state]);

  return (
    <div className="filterSheetMain">
      <div className="cp-header textcenter">
        <h3>Filters</h3>
        <CloseIcon onClick={() => closeFilterSheet()} />
      </div>
      <div className="filter-row">
        <div className="head-row">
          <h5>Categories</h5>
        </div>
        <div className="f-row">
          {state.category.map((item, index) => {
            return (
              <div
                className={`filter-box ${
                  categorylist?.includes(item) ? "active" : ""
                }`}
                key={index}
                onClick={() => handleChange("category", item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="filter-row">
        <div className="head-row">
          <h5>Days</h5>
        </div>
        <ul className="filter-row-ul">
          {daysFilter?.map((item, index) => (
            <li key={index}>
              <input
                type="checkbox"
                className="tag_filter"
                checked={search_days?.includes(item.value)}
                id={"day_" + index}
                value={item.value}
                onChange={(ev) => handleChange("days", item?.value)}
              />
              <label htmlFor={"day_" + index}>{item.label}</label>
            </li>
          ))}
        </ul>
      </div>
      <>
        <div className="filter-row">
          <div className="head-row">
            <h5>Themes </h5>{" "}
            <span
              onClick={() =>
                currentThis.setState({
                  showMorefilter: !state.showMorefilter,
                })
              }
              className="showMoreBtn"
            >
              {state.showMorefilter ? "Show less" : "Show more"}
            </span>
          </div>
          <div className="f-row">
            {state.segments?.map((item, index) => {
              const displayedSegments = new Set();
              const filtersegment = item.segment_short_name;
              if (displayedSegments.has(filtersegment)) {
                return null;
              }
              displayedSegments.add(filtersegment);
              return (!state.showMorefilter && index < 5) ||
                state.showMorefilter ? (
                <div
                  className={`filter-box ${
                    state.selectedsegment.includes(filtersegment)
                      ? "active"
                      : ""
                  }`}
                  key={index}
                  onClick={() => currentThis?.handleChange("theme", item)}
                >
                  {filtersegment}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </>
      {state?.packageType === "uop" && (
        <>
          <div className="filter-row">
            <div className="head-row">
              <h5>Months </h5>{" "}
              <span
                onClick={() =>
                  currentThis.setState({
                    showMoreMonths: !state.showMoreMonths,
                  })
                }
                className="showMoreBtn"
              >
                {state.showMoreMonths ? "Show less" : "Show more"}
              </span>
            </div>
            <ul className="filter-row-ul">
              {nextYearMonths?.map((item, index) => {
                return (!state.showMoreMonths && index < 5) ||
                  state.showMoreMonths ? (
                  <li key={index}>
                    <input
                      type="checkbox"
                      className="tag_filter"
                      checked={state.selectedMonth.includes(item?.value)}
                      id={"day_" + index}
                      value={item.value}
                      onChange={() =>
                        currentThis.handleChange("month", item?.value)
                      }
                    />
                    <label htmlFor={"day_" + index}>{item.label}</label>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        </>
      )}
      <div className="filter-body slider">
        <div className="filter-row pricerange-option">
          <div className="head-row">
            <h5>Price </h5>{" "}
          </div>
          <ul className="filter-row-ul">
            {priceFilterOptions?.map((item, index) => {
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    className="tag_filter"
                    checked={priceFilter === item?.value}
                    id={"price_" + index}
                    value={item.value}
                    onChange={() => handlePriceFilter(item?.value)}
                  />
                  <label htmlFor={"price_" + index}>{item.label}</label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="filter-action">
        <a
          href="#"
          className="button"
          onClick={() => {
            handleApplyFilter();
            closeFilterSheet();
          }}
        >
          Apply
        </a>
        <a
          href="#"
          className="button ghost-button"
          onClick={() => {
            handleClearAll();
            closeFilterSheet();
          }}
        >
          Clear All
        </a>
      </div>
    </div>
  );
};

export default React.memo(FilterSheet);
